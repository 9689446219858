/* eslint-disable */
import { Box, Button as MuiButton, InputLabel, Typography, OutlinedInput, Input } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { Notification, useLogin, useTranslate, useSetLocale } from 'react-admin';
import { Field, Form } from 'react-final-form';
import ReactHelmet from 'react-helmet';
import AuthWrapper from '../../../theme/@jumbo/components/Common/authComponents/AuthWrapper';
import ContentLoader from '../../../theme/@jumbo/components/ContentLoader';
import { withStandardButtonProps } from '../../hoc/with-standard-button-props';
import { TextInput } from '../ra/inputs';
import { appBrandConfigMapping } from '../../../constant/appBrandMapping';
import { getBackofficeEnv } from '../../../services/util';

const Button = withStandardButtonProps(MuiButton);

const useStyles = makeStyles(theme => ({
  // Global wrapper style for full page background
  root: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default, // Set background for full page
  },
  // authThumb: {
  //   backgroundColor: alpha(theme.palette.primary.main, 0.12),
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   padding: 20,
  //   width: '100%',
  //   // maxWidth: '400px',
  //   display: 'none',
  //   [theme.breakpoints.up('md')]: {
  //     width: '50%',
  //     order: 2,
  //     display: 'flex',
  //     alignItems: 'center',
  //     justifyContent: 'center',
  //   },
  // },
  authContent: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fafafa',

    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
      padding: 30,
      flexDirection: 'row',

      // background: 'url(/images/mbo-logos/login-illustrations.png)',
      backgroundSize: 'auto 100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom left',
    },

    [theme.breakpoints.up('xl')]: {
      padding: '50px 30px',
    },
  },
  titleRoot: {
    marginBottom: 14,
    fontWeight: 600,
    color: theme.palette.text.primary,
    
    textAlign: 'center',
    fontWeight: 600,
    paddingBottom: 10,


    // [theme.breakpoints.up('md')]: {
    //   // color: theme.palette.text.primary,
    //   fontWeight: 'normal',
    // },
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.black, 0.12),
    },
    '& .MuiOutlinedInput-input': {
      paddingTop: 10.5,
      paddingBottom: 10.5,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 13px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    // paddingTop: 10,
    // paddingBottom: 50,
  },
  btnLogin: {
    backgroundColor: ({ defaultTheme }) => defaultTheme.palette.primary.main,
    '&:hover': {
      backgroundColor: ({ defaultTheme }) => defaultTheme.palette.primary.light,
    },
  },
  illustrationWrap: {
    flex: 1,
  },
  // illustration: {
  //   display: 'none',
  //   [theme.breakpoints.up('md')]: {
  //     display: 'block',
  //   },
  // },
  // logo: {
  //   display: 'flex',
  //   justifyContent: 'center',
  //   marginBottom: 16,

  //   [theme.breakpoints.up('md')]: {
  //     display: 'block',

  //     marginTop: -10,
  //     marginLeft: -15,
  //     marginBottom: 0,
  //   },
  // },
  loginFormContainer: {
    flex: 1,
    '& input': {
      backgroundColor: '#ffffff',
      borderRadius: '4px',
    },
  },
}));

const GreenPacificQueenLoginPage = ({
  variant = 'default', wrapperVariant = 'cheat-mode', theme,
}) => {
  const setLocale = useSetLocale();
  const login = useLogin();
  const translate = useTranslate();
  const classes = useStyles({
    variant,
    defaultTheme: theme,
  });

  const { APP_BRAND } = getBackofficeEnv();
  const { companyName } = appBrandConfigMapping[APP_BRAND];

  const onSubmit = values => {
    login({
      ...values,
    }).catch(() => {
      // Error message is handled at the axios interceptor
    });
  };

  const handleValidation = values => {
    const errors = {};
    // if (!values.playerId) {
    //   errors.playerId = translate('ra.validation.required');
    // }

    // if (!values.opCode) {
    //   errors.opCode = translate('ra.validation.required');
    // }
    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }

    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  useEffect(() => {
    setLocale('en'); // need to set locale to get translations from server
  }, []);

  return (
    <Box className={classes.root}>
      <AuthWrapper
        variant={wrapperVariant}
        cardStyle={{
          borderRadius: 20,
          boxShadow: 'none',
          maxWidth: '400px',
        }}
      >
        <ReactHelmet>
          <title>{`${companyName} | Streamer marketing back office`}</title>
        </ReactHelmet>
        <Box
          className={classes.authContent}
          flex="1"
        >
          <Box className={classes.loginFormContainer}>
            <Typography
              component="div"
              variant="h4"
              in
              className={classes.titleRoot}
            >
              {translate('ra.auth.welcome')}
            </Typography>
            <Form
              onSubmit={onSubmit}
              validate={handleValidation}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    name="username"
                    component={TextInput}
                  >
                    {({
                      input, meta,
                    }) => (
                      <>
                        <InputLabel
                          htmlFor="username"
                          shrink
                        >
                          {translate('ra.auth.username')}
                          {' '}
                          *
                        </InputLabel>
                        <TextInput
                          {...input}
                          id="username"
                          type="text"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          placeholder="Please enter" // Placeholder remains visible when the field is focused
                          className={classes.textFieldRoot}
                          error={!!(meta.touched && meta.error)}
                          helperText={meta.touched && meta.error ? meta.error : ''}
                          InputLabelProps={{
                            shrink: true, // This ensures that the label behaves correctly when there is a value or focus
                          }}
                        />
                      </>
                    )}
                  </Field>

                  <Field
                    name="password"
                    component={TextInput}
                  >
                    {({
                      input, meta,
                    }) => (
                      <>
                        <InputLabel
                          htmlFor="password"
                          shrink
                        >
                          {translate('ra.auth.password')}
                          {' '}
                          *
                        </InputLabel>
                        <TextInput
                          {...input}
                          id="password"
                          type="text"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          className={classes.textFieldRoot}
                          placeholder={meta.touched && meta.error ? meta.error : 'Please enter'}
                          error={!!(meta.touched && meta.error)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </>
                    )}
                  </Field>
                  <Box mt={2.5}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      className={classes.btnLogin}
                      style={{
                        backgroundColor: '#5d9cf4',
                      }}
                    >
                      {translate('ra.auth.sign_in')}
                    </Button>
                  </Box>
                </form>
              )}
            />
          </Box>
          <ContentLoader />
        </Box>
        <Notification />
      </AuthWrapper>
    </Box>
  );
};

export default GreenPacificQueenLoginPage;
