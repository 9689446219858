import { Box, darken, IconButton, Popover, Menu, MenuItem } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import { RefreshSharp } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import React, { useCallback, useEffect, useState } from 'react';
import { useNotify, useRefresh, useTranslate, useLoading } from 'react-admin';
import HeaderInfo from '../../../../../../../base/components/layout/headerInfo';
import SidebarToggleHandler from '../../../../../../@coremat/CmtLayouts/Horizontal/SidebarToggleHandler';
import LanguageSwitcher from '../../LanguageSwitcher';
import Logo from '../../Logo';
import UserDropDown from '../../UserDropDown';
import { getBackofficeEnv } from '../../../../../../../services/util';
import { useAuthUser } from '../../../../../../../base/hooks';
import { useReloadContext } from '../../../../../../../base/context/reload/reload.context';
import Axios from 'axios';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: 10,
    padding: 0,
    '& .Cmt-appIcon': {
      color: theme.palette.text.secondary,
      '&:hover, &:focus': {
        color: darken(theme.palette.text.secondary, 0.2),
      },
      [theme.breakpoints.down('xs')]: {
        padding: 7,
      },
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  langRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 5,
    paddingRight: 5,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: -3,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: alpha(theme.palette.common.dark, 0.15),
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      right: 0,
      top: -3,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: alpha(theme.palette.common.dark, 0.15),
    },
  },
  searchIcon: {
    [theme.breakpoints.down('xs')]: {
      padding: 9,
    },
  },
  transactionWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  refreshIcon: {
    color: theme.palette.primary.contrastText,
  },
  viewButton: {
    color: 'white',
    '&:hover': {
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  // viewButton: {
  //   color: theme.palette.primary.main,
  // },
  // menuItem: {
  //   color: 'black',
  //   backgroundColor: '#b8b0b0', // light grey background
  //   '&:hover': {
  //     backgroundColor: '#7dc97f', // greenish highlight on hover
  //     color: '#000000', // Black text on hover for contrast
  //   },
  // },
  // subMenu: {
  //   position: 'absolute',
  //   left: '100%', // Place the sub-menu to the right of the outer menu
  //   top: 0, // Align with the top of the outer menu
  //   backgroundColor: '#b8b0b0', // Same background as outer menu for consistency
  //   zIndex: 1300, // Ensure the sub-menu appears above other content
  // },
  // subMenuItem: {
  //   color: 'black',
  //   backgroundColor: '#b8b0b0',
  //   '&:hover': {
  //     backgroundColor: '#7dc97f',
  //     color: '#000000',
  //   },
  // },
  menuItem: {
    color: 'black',
    // backgroundColor: '#b8b0b0', // light grey background
    '&:hover': {
      backgroundColor: '#7dc97f', // greenish highlight on hover
      color: '#000000', // Black text on hover for contrast
    },
  },
  subMenuItem: {
    color: 'black',
    // backgroundColor: '#b8b0b0',
    '&:hover': {
      backgroundColor: '#7dc97f',
      color: '#000000',
    },
  },
}));

const GoToLobbyPageComponent = () => {
  const user = useAuthUser();
  const translate = useTranslate();
  const env = getBackofficeEnv();
  const classes = useStyles();

  const [cheatModeData, setCheatModeData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState({});

  // Fetch cheat mode data
  useEffect(() => {
    const fetchCheatModeLink = async () => {
      try {
        const response = await Axios.get('/api/cheat-mode-link');
        if (response.status === 200) {
          // console.log('Cheat mode link data', response.data.data);
          setCheatModeData(response.data.data);
        } else {
          console.error('Unexpected status code:', response.status);
        }
      } catch (error) {
        console.error('Error fetching cheat mode link:', error);
      }
    };

    fetchCheatModeLink();
  }, []); // Empty dependency array ensures this runs once when the component mounts

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Main menu close handler
  const handleClose = () => {
    setAnchorEl(null);
    setSubMenuAnchorEl({});
  };

  // Sub-menu click handler
  const handleSubMenuClick = (brandCode, event) => {
    setSubMenuAnchorEl((prev) => ({
      ...prev,
      [brandCode]: event.currentTarget,
    }));
  };

  // Sub-menu close handler
  const handleSubMenuClose = (brandCode) => {
    setSubMenuAnchorEl((prev) => ({
      ...prev,
      [brandCode]: null,
    }));
  };

  const handleRedirect = (brandCode, nativeId) => {
    const lobbyUrl = new URL(env.LOBBY_PAGE_URL);
    // lobbyUrl.searchParams.append('t', user?.id || '');
    lobbyUrl.searchParams.append('oc', brandCode);
    lobbyUrl.searchParams.append('t', nativeId || '');
    window.open(lobbyUrl.href, '_blank');
  };

  return (
    <Box>
      <Box className={clsx('Cmt-i18n-switch')}>
        <Tooltip title="Lobby Page" className={classes.viewButton}>
          <IconButton onClick={handleClick}>
            <ArrowDropDownIcon />
          </IconButton>
        </Tooltip>

        {/* Main Popover for brandCode */}
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box>
            {cheatModeData.length > 0 ? (
              cheatModeData.map(({ brandCode, players }) => (
                <div key={brandCode}>
                  <MenuItem
                    className={classes.menuItem}
                    onClick={(event) => handleSubMenuClick(brandCode, event)}
                  >
                    {brandCode}
                  </MenuItem>

                  {/* Sub-popover for nativeId */}
                  <Popover
                    anchorEl={subMenuAnchorEl[brandCode]}
                    open={Boolean(subMenuAnchorEl[brandCode])}
                    onClose={() => handleSubMenuClose(brandCode)}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <Box>
                      {players.length > 0 ? (
                        players.map((player) => (
                          <MenuItem
                            key={player.nativeId}
                            className={classes.subMenuItem}
                            onClick={() => handleRedirect(brandCode, player.nativeId)}
                          >
                            {player.nativeId}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No players available</MenuItem>
                      )}
                    </Box>
                  </Popover>
                </div>
              ))
            ) : (
              <MenuItem disabled>No player token being linked yet</MenuItem>
            )}
          </Box>
        </Popover>
      </Box>
    </Box>
  );
};

const HeaderTop = () => {
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const { setValuesContext } = useReloadContext();

  const pageLoading = useLoading();

  const handleRefresh = useCallback(() => {
    refresh();
    notify(translate('ra.notification.refreshed', 'success'));

    return () => {
      if (!pageLoading) {
        setValuesContext({});
      }
    };
  }, [notify, refresh, translate, setValuesContext, pageLoading]);

  return (
    <Toolbar className={classes.root}>
      <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />
      <Logo
        mr={{
          xs: 2,
          sm: 4,
          lg: 6,
          xl: 8,
        }}
      />
      <Box display="flex" alignItems="center" ml={{ base: "'unset'", sm: 'auto' }}>
        <Hidden xsDown>
          <HeaderInfo />
          <IconButton aria-label="Refresh" aria-haspopup="true" onClick={handleRefresh} color="default">
            <RefreshSharp className={classes.refreshIcon} />
          </IconButton>
        </Hidden>
        <Box
          display={{
            xs: 'none',
            sm: 'block',
          }}
        >
          <Box className={clsx(classes.langRoot, 'Cmt-i18n-switch')}>
            <LanguageSwitcher />
          </Box>
        </Box>

        <GoToLobbyPageComponent />
        <UserDropDown />
      </Box>
    </Toolbar>
  );
};

export default HeaderTop;
