/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import {
  Box, Button as MuiButton, Card, CardContent, TextField, IconButton, makeStyles, Chip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Form, Field } from 'react-final-form';
import { useNotify, useRefresh, useTranslate } from 'react-admin';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    backgroundColor: '#d0e3c3',
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  button: {
    marginTop: theme.spacing(2),
  },
  // inputHighlighted: {
  //   backgroundColor: '#d0e3c3', // Light green background for strong highlight
  // },
}));

const TriggerLinkForm = ({
  formId, initialData, onSubmit, onClear,
}) => {
  const classes = useStyles();

  // Initialize playerTokens correctly, ensuring new forms can add tokens
  const [playerTokens, setPlayerTokens] = useState(
    initialData?.players?.length > 0
      ? initialData.players.map(player => ({
          nativeId: player.nativeId,
          key: uuidv4(),
        }))
      : [] // Ensure new forms start with an empty array
  );

  const [inputValue, setInputValue] = useState('');
  const [formChanged, setFormChanged] = useState(false);

  // Custom submit logic for create mode
  const handleCustomSubmit = values => {
    onSubmit(formId, {
      brandCode: values.brandCode,
      players: playerTokens.map(pt => ({
        nativeId: pt.nativeId,  // Ensure nativeId is mapped correctly
      })),
    });
    setFormChanged(false);
  };

  const handleAddPlayerToken = event => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      setPlayerTokens([...playerTokens, {
        nativeId: inputValue.trim(),  // Add the entered token
        key: uuidv4(), 
      }]);
      setInputValue(''); // Clear input field after adding the token
      setFormChanged(true);
    }
  };

  const handleDeletePlayerToken = nativeIdToDelete => {
    setPlayerTokens(playerTokens.filter(token => token.nativeId !== nativeIdToDelete));
    setFormChanged(true);
  };

  return (
    <Card className={classes.card}>
      <CardContent>
        <Form
          onSubmit={handleCustomSubmit}
          initialValues={initialData}
          render={({
            handleSubmit, form, submitting, pristine,
          }) => (
            <form onSubmit={handleSubmit}>
              <div>
                <Field name="brandCode">
                  {({ input }) => (
                    <TextField
                      {...input}
                      label="Brand Code"
                      fullWidth
                      margin="normal"
                      value={input.value || ''}
                      onChange={input.onChange}
                      disabled={!!input.value && form.dirty}
                    />
                  )}
                </Field>
              </div>
              <div>
                <TextField
                  label="Press Enter to add to a list"
                  fullWidth
                  margin="normal"
                  value={inputValue}
                  onChange={e => setInputValue(e.target.value)}
                  onKeyPress={handleAddPlayerToken}
                />
                <Box className={classes.chipContainer}>
                  {playerTokens.map(({ nativeId, key }) => (
                    <Chip
                      key={key}
                      label={nativeId}
                      onDelete={() => handleDeletePlayerToken(nativeId)}
                      className={classes.chip}
                    />
                  ))}
                </Box>
              </div>
              <Box mt={2}>
                <MuiButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={submitting || playerTokens.length === 0 || (!formChanged && pristine)}
                >
                  Submit
                </MuiButton>
                <MuiButton
                  variant="outlined"
                  color="contained"
                  onClick={onClear} // Trigger the onClear callback when clicked
                  style={{ marginLeft: '10px' }}
                >
                  Clear
                </MuiButton>
              </Box>
            </form>
          )}
        />
      </CardContent>
    </Card>
  );
};

const TriggerLinkComponent = () => {
  const [forms, setForms] = useState([]);
  const notify = useNotify();
  const refresh = useRefresh();
  const t = useTranslate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Axios.get('/api/cheat-mode-link');
        const data = response.data.data.map(item => ({
          ...item,
          players: item.players || [], // Ensure players array exists
        }));
        setForms(data);
      } catch (error) {
        notify('Error fetching data', 'error');
      }
    };

    fetchData();
  }, [notify]);

  const handleFormSubmit = async (formId, values) => {
    try {
      console.log("handleFormSubmit", values); 
      // Find the form by formId to see if it has an existing `id` from the backend
      const existingForm = forms.find(form => form.id === formId);

      if (existingForm?.id) {
        // Existing form with backend `id` -> PATCH (update)
        await Axios.patch(`/api/cheat-mode-link/${existingForm.id}`, {
          brandCode: values.brandCode,
          players: values.players,
        });
        notify(t('ra.notification.updated', {
          smart_name: t('ra.text.triggerLink'),
        }), 'success');
      } else {
        // New form without backend `id` -> POST (create)
        const response = await Axios.post('/api/cheat-mode-link', {
          brandCode: values.brandCode,
          players: values.players,
        });

        // Update the form with the returned `id` from the backend after POST
        setForms(forms.map(form =>
          form.id === formId
            ? { ...form, id: response.data.id } // Assign backend `id`
            : form
        ));
        notify(t('ra.notification.created', {
          smart_name: t('ra.text.triggerLink'),
        }), 'success');
      }
      refresh();
    } catch (e) {
      notify('Error submitting form', 'error');
    }
  };

  const handleAddForm = () => {
    setForms([...forms, {
      id: null, // New form doesn't have an `id` yet
      brandCode: '',
      players: [],
    }]);
  };

  const handleClearForm = (formId) => {
    // Filter out the form with the given formId
    setForms(forms.filter(form => form.id !== formId));
  };

  return (
    <div>
      {forms.map(formData => (
        <TriggerLinkForm
          key={formData.id || uuidv4()} // Use uuidv4 as key for unsaved forms
          formId={formData.id || uuidv4()} // Assign a unique id for new forms
          initialData={formData}
          onSubmit={handleFormSubmit}
          onClear={() => handleClearForm(formData.id || formData.formId)} // Pass formId to the onClear callback
        />
      ))}
      <Box mt={2}>
        <MuiButton
          variant="contained"
          color="primary"
          onClick={handleAddForm}
          startIcon={<AddIcon />}
        >
          Link another brand with player token(s) 
        </MuiButton>
      </Box>
    </div>
  );
};


export default TriggerLinkComponent;